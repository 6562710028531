export const data = [
  "....sign of evil number four ",
  "0 days without a suicide ",
  "a law requiring ducks to wear long pants ",
  "add more balls ",
  "america junior ",
  "america's crud bucket ",
  "and all who dwell within this rocket house ",
  "annual gift man ",
  "assal horizontology ",
  "avoision ",
  "baby guts ",
  "bachelor arms ",
  "badbye ",
  "bagzooka ",
  "banana kaboom ",
  "bartesque ",
  "basegame ",
  "bathiola ",
  "bathtub mint julep ",
  "be-musement park ",
  "beginualize ",
  "beginulate ",
  "betsy bleedingheart ",
  "biz-zay ",
  "blingwad ",
  "blubber-in-law ",
  "blundering numbskullery ",
  "bolognium ",
  "bonus eruptus ",
  "boo-urns ",
  "boostafazoo ",
  "bort ",
  "bovine university ",
  "brain burger with extra pus ",
  "brassafrax ",
  "bumbled-bee ",
  "california cheeseburger ",
  "capdabbler ",
  "car hole ",
  "catsup ",
  "chazwazers ",
  "cheese-eating surrender monkeys ",
  "chestal ",
  "chester a. arthritis ",
  "chocotastic ",
  "christmas ape ",
  "christmas ape goes to summer camp ",
  "clouseauesque ",
  "commie-nazis ",
  "continuing our 'sign of evil' countdown ",
  "crantastic ",
  "crap factory ",
  "craptacular ",
  "crayola oblongata ",
  "crazy critter ",
  "crisitunity ",
  "cromulent ",
  "d'oh! ",
  "dead serious about ",
  "dealie ",
  "death sports ",
  "debigulator ",
  "deceleratrix ",
  "deceleratrix ",
  "delicious caulk ",
  "dickety ",
  "dickety-six miles ",
  "diddily ",
  "diddly ",
  "dirty, maybe. dangerous, hardly. ",
  "disgruntled goat ",
  "doctorb ",
  "dodgerock ",
  "dollareydoos ",
  "don'ter ",
  "doobob ",
  "doodily ",
  "dorkus molorkus ",
  "dumbening ",
  "dungeonarium ",
  "eat my shirt ",
  "eat my shorts ",
  "eat our shirts ",
  "eddie punch-clock ",
  "eedily ",
  "electromicide ",
  "embiggen ",
  "esquilax ",
  "esquilax ",
  "everybody get naked ",
  "explosion land ",
  "eyeball stew ",
  "fancy store-bought dirt ",
  "fantastipotamus ",
  "fatard ",
  "faxtrola ",
  "fireworks factory ",
  "fishmas ",
  "flanswered ",
  "flunjer ",
  "foodbag ",
  "foodrinkery ",
  "forfty ",
  "forty-two bathtubs, please ",
  "foundling-a-ding ",
  "four krustys ",
  "four minutes. ",
  "frinkahedron ",
  "fudrucker ",
  "gamblor ",
  "gangsta city ",
  "garbagewater ",
  "genius at work ",
  "gentle genius ",
  "glavin ",
  "go to your room, lisa ",
  "grandpas little helper ",
  "groin-grabbingly ",
  "guilt-didilly-ilty as char-didilly-arged ",
  "ha ha! ",
  "have been either drunk or on cocaine ",
  "here's vanessa williams ",
  "hey-dilly-ho ",
  "highway 9 bird sanctuary ",
  "holy flurking schnit ",
  "homerhol ",
  "homersexual ",
  "horse with a head of a rabbit and the body of a rabbit ",
  "hot jazz music ",
  "houseware warehouse ",
  "i had to sell the trigger and most of the handle to feed my family ",
  "i kicked a giant mouse in the butt ",
  "i need the biggest seed bell you have ",
  "iced cream ",
  "impy & chimpy ",
  "introubulate ",
  "invisible dog leash ",
  "it works just like regular money, but it's fun ",
  "jebeditis ",
  "jebus ",
  "jeremy's iron ",
  "jiminy jillikers ",
  "johnny come-not-lies ",
  "johnny lunchpail ",
  "joy, excitement, looking ",
  "jumping box ",
  "just like in real life ",
  "juzz ",
  "kapowza ",
  "ketchup ",
  "killbot factory ",
  "knifey spooney ",
  "knifey wifey ",
  "knowitallism ",
  "knowledgeum ",
  "ku klux klam ",
  "kung-fu hippie ",
  "kwyjibo ",
  "lamarella ",
  "land cow ",
  "land monster ",
  "lard-ho ",
  "learnatorium ",
  "liberty log ",
  "like... all those people in jail ",
  "lisa, go to your room ",
  "lord protect this rocket house ",
  "low-income nobodies ",
  "lupper ",
  "magumba ",
  "malparkage ",
  "mazuma ",
  "megacide ",
  "meh ",
  "menapplause ",
  "menu boy ",
  "microcalifragilistics ",
  "milhousing ",
  "mobilomobile ",
  "money fight ",
  "mono = one ",
  "municipal house of pancakes ",
  "municipal roundtable ",
  "my children need wine ",
  "my son is also named bort ",
  "mysterious liquor clouds ",
  "needs more dog ",
  "neglecterino ",
  "neighborino ",
  "nervous pervis ",
  "nincompoop ",
  "no ones pinching his legs ",
  "no-breath ",
  "no, money down! ",
  "not back, avenge deaths ",
  "nothing can possi-blye go wrong ",
  "nuclear whipping boy ",
  "nucleon ",
  "nuisancefon ",
  "nulecule ",
  "okely dokely ",
  "one way passage to the boneyard ",
  "onetuplet ",
  "oodily ",
  "totally outrageous paradigm ",
  "ovulicious ",
  "paradigm ",
  "paraplegiarino ",
  "parents' island ",
  "pasghetti ",
  "perspicacity ",
  "pinnitchy-o ",
  "please let me explain ",
  "please, kill me. ",
  "pointy kitty ",
  "pollutinest ",
  "poochie ",
  "poochie death threats ",
  "poochie hate mail ",
  "poochie hate mail (foreign) ",
  "poor wiggum. ",
  "posturologists ",
  "pretzeled bread ",
  "pricetaggery ",
  "proactive ",
  "purple is a fruit ",
  "quetzalzacatenango ",
  "rail = rail ",
  "rambunctious yahoos ",
  "rappin' surfer ",
  "rastafy ",
  "redorkulated ",
  "retirony ",
  "reverse vampires ",
  "reversifying glass ",
  "riding the midnight train to slab city ",
  "rock and/or roll ",
  "sacrilicious ",
  "sally housecoat ",
  "sandal-wearing goldfish tenders ",
  "saxamaphone ",
  "scalpal ",
  "science pole ",
  "scientician ",
  "scotchtoberfest ",
  "scratchtasia ",
  "searing gas pain land ",
  "senseless dunderpate ",
  "shark boy ",
  "she. u.i. ",
  "shinning ",
  "shiva h. vishnu ",
  "silicon chips and such ",
  "skanks for nothing ",
  "skittlebrau ",
  "smarch ",
  "smashy smashy ",
  "smell ya later ",
  "smendler ",
  "smokesperson ",
  "smuggled vegetables ",
  "snacktacular ",
  "sophistimacated doowhackey ",
  "speedholes ",
  "spiritual de-pantsing ",
  "spokesrebel ",
  "squozen ",
  "stabby ",
  "steamed hams ",
  "stinkables ",
  "successmanship ",
  "such a violent motif ",
  "suck like a fox ",
  "suck shack ",
  "sunblocker ",
  "superliminal ",
  "suspicious aloysius ",
  "swedish lunchbox ",
  "swishifying ",
  "t.g.i. mcscratchy's ",
  "telepanhandling ",
  "texas left ",
  "texas penny ",
  "that's the first thing that's ever gone wrong ",
  "that's why your robot never worked ",
  "the cause of and solution to all of life's problems. ",
  "the congealed group ",
  "the e-z road to success ",
  "the nauseator ",
  "the old fork in the eye ",
  "the old wiggum charm ",
  "the whipped group ",
  "there's no need to murmur ",
  "they tried that in the movies and it didn't work ",
  "this stupid country ",
  "throughhole ",
  "tomacco ",
  "tomorry ",
  "torture land ",
  "traumedy ",
  "tromboner ",
  "tubby ",
  "unblowuppable ",
  "uncle ant ",
  "underreacting ",
  "unfaceuptoable ",
  "unnecessary surgery land ",
  "unpossible ",
  "unpossible ",
  "up and at them ",
  "uppity box ",
  "velocitator ",
  "we were eating rotisserie chicken ",
  "well-wisher ",
  "well, that's the end of me ",
  "well, you all know what laughter sounds like. ",
  "where's poochie? ",
  "wiggle puppy ",
  "wizard's key ",
  "woozle wuzzle ",
  "word hole ",
  "works on contingency? ",
  "world-class chemical dependency center ",
  "worst episode ever. ",
  "wow, this is so much like my dreams it's scary ",
  "yoink ",
  "you are hearing me talk ",
  "your homemade liquor is exploding again. ",
  "your older, balder, fatter son ",
  "zazz ",
  "zing ",
  "zork ",
  "zuh ",
];
