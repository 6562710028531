import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { data } from './data.js'
import * as serviceWorker from './serviceWorker';

const style = {
    margin: '0 10px 20px 0',
};

const ipsumStyle = {
    fontFamily: 'monospace',
};

const loremStyle = {
    marginBottom: '20px',
};

const LoremSimpsonTitle = () => {
    return (
        <div>
            <h1> Lorem Simpson</h1>
            <h3>A Lorem Ipsum generator for Simpsons fans</h3>
        </div>
    )
}

class LoremSimpson extends React.Component {
    state = {
        randomInt: 0,
        randomString: 'cromulent',
        randomIpsum: ['lorem ipsum perfectly cromulent'],
        wordCount: 70,
        paragraphCount: 3,
    }
    randomNumber = () => {
        const randomInt = Math.floor(Math.random() * data.length);
        this.setState({
            randomInt,
        })
        return randomInt;
    }

    randomString = () => {
        this.setState({
            randomString: data[this.state.randomInt],
        })
    }

    updateWordCount = (event) => {
        this.setState({
            wordCount: event.target.value,

        })
    }

    updateParagraphCount = (event) => {
        this.setState({
            paragraphCount: event.target.value,

        })
    }

    randomIpsum = () => {
        this.randomNumber();
        this.randomString();
        let randomIpsumGroup = [];
        for(let j = 0;j < this.state.paragraphCount; j++){
            let randomIpsum = "";

            for(let i = 0; i < this.state.wordCount;i++) {
                randomIpsum += data[this.randomNumber()]
            }
            randomIpsumGroup.push(randomIpsum)
        }
        this.setState({
            randomIpsum: randomIpsumGroup
        })
    }

    render() {
        return(
            <div>
                <div>
                    Words per paragraph (approx): <input style={style} id="wordCount" placeholder="word count" type="number" value={this.state.wordCount} onChange={this.updateWordCount}></input>
                    # of Paragraphs: <input style={style} id="ParagraphCount" placeholder="paragraph count" type="number" value={this.state.paragraphCount} onChange={this.updateParagraphCount}></input>
                    <button onClick={this.randomIpsum}>REFRESH</button>
                </div>
                <div>
                    {this.state.randomIpsum.map((paragraph) => {
                        return (
                            <div style={loremStyle}>
                                {paragraph}
                            </div>
                        )
                    })}                
                </div>
            </div>
        );
    }
}

const LoremSimpsonPage = () => {
    return (
        <div style={ipsumStyle}>
            <LoremSimpsonTitle />
            <LoremSimpson />
        </div>
    )
}

ReactDOM.render(<LoremSimpsonPage />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
